<!-- 申请旗舰店 -->
<template>
  <div class="apply-container">
    <div class="fixed-title">
      <div class="img">
        <img src="http://th5.rolormd.com/7999/recruit/img/send/mmexport.png" height="32.5" width="32.5">
      </div>
      <div class="txt">更多便捷的支付方式，超多的钜惠活动</div>
      <div class="down" @click="sureApply">立即打开</div>
    </div>

    <div class="main-title">开旗舰店</div>
    <van-cell-group>
      <van-cell title="选择品类" class="brand-box">
        <van-radio-group v-model="brandId" direction="horizontal" checked-color="#a771ff" @change="onBrandChange">
          <van-radio :name="3">美妆</van-radio>
          <van-radio :name="4">大健康</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell value="**元">
        <template #title>
          开旗舰店可抵扣金额
          <van-icon name="question" color="#bd9bfb" size="16" @click="showMakeUpLayer = true" />
        </template>
      </van-cell>
      <van-cell title="店铺采购系统应付金额" :value="(brandId === 3 ? 7500 : 7500) + '元'" />
      <van-cell :value="(brandId === 3 ? 1500 : 1500) + '元'" :border="false">
        <template #title>
          旗舰店保证金
          <van-icon name="question" color="#bd9bfb" size="16" @click="showBondLayer = true" />
        </template>
      </van-cell>
      <van-cell :border="false">
        <div class="apply-prompt">
          <span>您本次支付的金额可用于后续在采购商品时使用，不可提现，不可申请退还。</span>
        </div>
      </van-cell>
      <van-cell class="pool-content">
        <van-checkbox v-model="agree" checked-color="#a771ff">我已阅读并同意<i @click.stop="showPoolLayer = true">《蜜蛋注册协议》</i></van-checkbox>
      </van-cell>
      <div class="operate">
        <van-button type="primary" color="#bd9bfb" round block @click="sureApply">立即开启</van-button>
      </div>
    </van-cell-group>
    <mi-shop-right :brand-id="brandId" :num="giftNum" />
    <van-popup v-model="showPoolLayer" position="bottom" style="height:100%">
      <Protocol @close="closePoolLayer" />
    </van-popup>
    <!-- 补差规则 -->
    <van-popup v-model="showMakeUpLayer" position="bottom">
      <agio-rule :isActivityOpen="upgradeInfo && upgradeInfo.isActivityOpen" />
    </van-popup>
    <!-- 保证金介绍 -->
    <van-popup class="layer-rule" v-model="showBondLayer">
      <div class="introduce">
        <div class="cancel" @click="showBondLayer = false">
          <van-icon name="clear" color="#999" size="20" />
        </div>
        <div class="title">保证金介绍</div>
        <div class="introduce-txt">
          <span class="num">1</span>
          <div>
            <p>美妆旗舰店主和大健康旗舰店主保证金补贴后合并为<span>3000元</span>：</p>
          </div>
        </div>
        <div class="introduce-txt">
          <span class="num">2</span>
          <div>
            <p>单独申请美妆旗舰店主补贴后，需缴纳<span>1500元</span>保证金；</p>
          </div>
        </div>
        <div class="introduce-txt">
          <span class="num">3</span>
          <div>
            <p>单独申请大健康旗舰店主，需缴纳<span>1500元</span>保证金；</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Protocol from "../../components/Brand/Protocol"
import MiShopRight from "./component/MiShopRight"
import { openMdApp } from "../../utils/appUtil"
import AgioRule from "../../components/Brand/AgioRule"
export default {
  name: 'applyPink',
  components: { Protocol, MiShopRight ,AgioRule},
  data() {
    return {
      brandId: 3,
      agree: false,
      showPoolLayer: false,
      showMakeUpLayer: false, //补差规则
      showBondLayer: false, //保证金介绍
      upgradeInfo: null,
      giftNum: '1~2', //礼包个数
      returnMethod: 1,//回归方式 1：全额回归 2：保证金回归
    }
  },
  created() {
    let { brandId } = this.$route.query;
    if (brandId) {
      this.brandId = brandId == '4' ? 4 : 3;
    }
     this.upgradeAgent();
  },
  methods: {
    sureApply: function () {
      let { shareUserId } = this.$route.query;
      openMdApp(this.baseHost, shareUserId);
    },
    closePoolLayer() {
      this.showPoolLayer = false;
    },
    onBrandChange() {
      this.upgradeInfo = null;
      this.upgradeAgent();
    },
    //代理升级首批款信息
    upgradeAgent: function () {
      this.upgradeInfo = null;
      this.codeNum = 0;
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true })
      this.get("/TeamAgent/UpgradeAgent/UpgradeInfo?", {
        brandid: this.brandId,
        v: 5, p: 1, returnType: 1,
      }).then(({ code, response, msg }) => {
        this.codeNum = code;
        this.$toast.clear();
        if (code === 1) {
          this.shareUserId = response.userId;
          this.giftNum = response.isActivityOpen ? '1' : '1~2';
          // payType   0 = 立即申请 1 = 铺货未完成 2 = 资料审核 3 = 已成为店主
          if (response.isBlue || response.IsAgent) {
            response.payType = 3;
          } else {
            let data = response.Request;
            if (data) {
              //提交资料 0  // 充值完成 1   // 用户确认 12
              // 客服确认升级 2  -------- 客服快链到升级页面或者添加页面去操作+审核页面连接按钮
              // 审核代理 3:  // 取消升级 99
              if (data.state === 2 || data.state === 12) {
                response.payType = 2;
              } else if (data.state === 3) {
                response.payType = 3;
              } else {
                response.payType = data.paytype
              }
            } else {
              response.payType = 0;
            }
          }
          //PayFirstMoney 需要支付的
          //FirstMoney 首批款
          this.upgradeInfo = response;
          let choiceList = response.ChoiceList || [];
          if (choiceList.length === 1) {
            this.returnMethod = choiceList[0].Choice;
          }
          this.isShowDiscount = response.isShowDiscount
        } else if (code === 2) {
          this.upgradeInfo = { payErrorTip: msg, payType: 2 };
        } else {
          this.upgradeInfo = { payErrorTip: msg };
        }
      })
    },
  },
  computed: {
  }
}
</script>
<style lang='scss' scoped>
@import '../../assets/style/applyStore';
.main-title {
  padding-top: 20px;
}
.rule-content {
  padding: 15px;
  text-align: left;
}
.layer-rule {
  border-radius: 8px;
}
.van-cell {
  /deep/.van-cell__title {
    flex: 2;
  }
  &.discount {
    background-color: #e5d7ff;
    .van-cell__title,
    .van-cell__value {
      color: #904cff;
    }
  }
}
.introduce {
  width: 260px;
  padding: 15px 25px 15px 15px;
  background: white;
  border-radius: 10px;
  position: relative;

  .cancel {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .title {
    text-align: center;
    font-size: 16px;
    color: #666666;
    margin-bottom: 10px;
  }

  .introduce-txt {
    .num {
      width: 15px;
      height: 15px;
      background: #ae81ff;
      text-align: center;
      color: white;
      border-radius: 50%;
      line-height: 15px;
      float: left;
      margin-top: 2px;
    }

    div {
      padding-left: 20px;

      p {
        color: #666666;
        font-size: 12px;
        text-align: justify;
        margin-bottom: 5px;

        span {
          color: #ae81ff;
        }
      }
    }
  }
}
.superior-info {
  padding: 11px 10%;

  .cus-content {
    display: flex;
    border-radius: 8px;
    padding: 11px 15px;
    align-items: center;
    background-color: #f8f8f8;

    > img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 6px;
    }

    .user {
      text-align: left;
      img {
        width: 50px;
        display: inline-block;
        vertical-align: middle;
      }

      .phone {
        padding-top: 5px;
        span {
          color: #999;
          font-size: 11px;
        }
      }
    }
  }
}
@media screen and (max-width: 361px) {
  .mi-content {
    .superior-info {
      padding: 11px 6%;
    }
  }
}
</style>