<!-- 补差规则 -->
<template>
  <div class="rule-content" v-html="content"></div>
</template>

<script>
export default {
  name: '',
  props: ['isActivityOpen'],
  data() {
    return {
      content: ''
    }
  },
  created() {

    this.getRuleContent();
  },
  methods: {
    getRuleContent() {
      let typeId = this.isActivityOpen ? 13 : 6;
      this.get("/Base/PublicTools/GetAgreementManage?", { typeId }, 2).then(({ code, response }) => {
        if (code === 1) {
          this.content = response.content;
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.rule-content {
  padding: 15px;
  text-align: left;
}
</style>